import React from 'react'
import { twMerge } from 'tailwind-merge'
import { ColorVariant } from '../theme/variants'

export interface BoxProps {
  /** Variant of alert box to render */
  colorVariant?: ColorVariant
  /** Optional padding classNames */
  padding?: string
  /** Any extra classes to pass to the component */
  className?: string
  /** Whether to show a border around the box, or not */
  showBorder?: boolean
  /** Children components to render */
  children: React.ReactNode
}

export function Box(props: BoxProps) {
  const {
    colorVariant = 'default',
    padding = 'px-3 py-2',
    className = '',
    showBorder = true,
    children,
    ...restOfProps
  } = props

  const classes: { [key in ColorVariant]: string } = {
    lightSuccess: 'border-primary border bg-primary-25 text-primary-600',
    success: 'border-primary border bg-primary-200 text-primary-600',
    warning: 'border-orange-400 border bg-orange-50 text-orange-600',
    danger: 'border-red-400 border bg-red-50 text-red-600',
    neutral: 'bg-shades-600 text-white',
    default: 'border-shades-200 border bg-white text-shades-600',
    primary: 'bg-primary text-white',
  }

  return (
    <div
      className={twMerge(
        'overflow-hidden rounded',
        `${padding} ${classes[colorVariant]} ${className}`,
        `${showBorder ? '' : 'border-[0px]'}`
      )}
      {...restOfProps}
    >
      {children}
    </div>
  )
}

export default Box
