import { joinClassNames } from '@unreserved-frontend-v2/utils/join-class-names'
import { Box } from '../box/box'
import { ColorVariant } from '../theme/variants'
export interface BadgeProps {
  width?: 'auto' | 'wide'
  colorVariant?: ColorVariant
  className?: string
  showBorder?: boolean
  children?: React.ReactNode
}

export function Badge({ width, colorVariant, className, showBorder, children, ...restOfProps }: BadgeProps) {
  const badgeClasses = 'inline-block text-xs font-bold text-center align-top'
  const widthClasses = { auto: 'w-auto', wide: 'w-32' }

  const classNames = joinClassNames(badgeClasses, widthClasses[width || 'auto'], className || '')

  return (
    <Box
      colorVariant={colorVariant}
      padding={'py-1 px-2'}
      className={classNames}
      showBorder={showBorder}
      {...restOfProps}
    >
      {children}
    </Box>
  )
}

export default Badge
